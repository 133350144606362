/* Variables */

:root {
  --colour-blue: #A2C1E0;
  --colour-green: #036016;
  --colour-orange: #F17105;
}

/* App */

.App {
  text-align: center;
}

.solved,
.valid {
  color: var(--colour-green);
}

.not-solved {
  font-weight: bold;
}

.wip,
.impossible {
  color: #666;
}

.not-valid {
  color: #81171B;
  font-weight: bold;
}

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.chosen-mode {
  color: var(--colour-orange);
  font-weight: bold;
}

.italics {
  font-style: italic;
}

lottie-player {
  position: fixed;
  left: 50vw;
  transform: translateX(-50%);
}

/* Calculator */

.grid {
  display: grid;
  grid-template-columns: repeat(4, 64px);
  grid-template-rows: minmax(96px, auto) repeat(5, 64px);
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 18px;
}

.grid > div,
.how-to-grid > div,
.how-to-calc > div {
  outline: solid black 2px;
  height: 100%;
  width: 100%;
}

.button,
.how-to-grid > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-to-calc {
  display: grid;
  grid-template-columns: repeat(4, 64px);
  grid-template-rows: minmax(96px, auto);
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.span-four {
  grid-column: span 4;
}

.span-two {
  grid-column: span 2;
}

.output-calculation {
  width: 256px;
  height: 32px;
  line-height: 32px;
  margin-top: 24px;
  padding: 0;

  font-size: 32px;
  text-align: center;

  outline: none;
  border-width: 0;
}

.caret {
  display: inline-block;
  width: 0px;
  position: relative;
}

.caret::before {
  background: black;
  color: transparent;
  height: 1em;
  width: 1px;
  top: -0.85em;
  position: absolute;
  content: "";

  animation-duration: 1s;
  animation-name: blink;
  animation-iteration-count: infinite;
}

@keyframes blink {
  from { opacity: 1; }
  49% { opacity: 1; }
  50% { opacity: 0; }
  99% { opacity: 0; }
  to { opacity: 1; }
 }

.output-value {
  margin-top: 8px;
  margin-bottom: 16px;

  line-height: 16px;
  font-size: 16px;
}

.output-value.valid {
  font-weight: bold;
}

.button:hover {
  cursor: pointer;
  font-size: 1.5em;
  outline: solid black 4px;
  z-index: 1;
}

.used {
  background-color: var(--colour-blue);
}

.equals {
  background-color: var(--colour-orange);
}

/* Answers Grid */

.answers-grid {
  display: grid;
  grid-template-columns: repeat(4, 80px);
  justify-content: center;
  align-items: center;
  font-family: monospace;
}

@media (min-width: 570px) {
  .answers-grid {
    grid-template-columns: repeat(7, 80px);
  }
}

/* How To */

.how-to-grid {
  display: grid;
  grid-template-columns: repeat(4, 64px);
  grid-template-rows: 64px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 18px;
}

.text-page {
  width: 300px;
  margin: auto auto;
}

.text-page p {
  color: #333;
}

/* Stats */

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 72px);
  justify-content: center;
  align-items: center;
}

.stats-number {
  font-size: 24px;
}

.stats-value {
  color: #666;
  font-size: 15px;
}

.stats-label{
  font-size: 15px;
}

.bar-chart {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bar-chart .seven-days {
  width: 40px;
}

.bar-chart .all-time {
  width: 56px;
}

.bar {
  width: 32px;
}

.seven-days .bar {
  margin-left: 4px;
}

.all-time .bar {
  margin-left: 12px;
}

.bar.orange {
  background-color: var(--colour-orange);
}

.bar.blue {
  background-color: var(--colour-blue);
}

/* Share */

.share-grid {
  white-space: pre-wrap;
}

.react-share__ShareButton {
  margin: auto 2px;
  opacity: 0.8;
}

.react-share__ShareButton:hover,
.react-share__ShareButton:focus {
  opacity: 1;
}

/* Footer */

footer {
  margin: 16px auto;
}

footer small {
  display: block;
}
